import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const NewsletterAPI = createApi({
    reducerPath: 'NewsletterAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            return headers
        },
    }),
    tagTypes: ['Newsletters', 'Newsletter'],
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        getNewsletters: builder.query({
            query: (path) => path,
            providesTags: ['Newsletters']
        }),
        getRandomNews : builder.query({
            query: (path) => path,
            providesTags: ['Newsletter']
        }),
        newsletterEnquiry: builder.mutation({
            query: ( url ,body) => ({
                url,
                method: 'POST',
                body,
            }),
            providesTags: ['Newsletter']
        })
    }),
});

export const { useGetNewslettersQuery , useGetRandomNewsQuery, useNewsletterEnquiryMutation} = NewsletterAPI;